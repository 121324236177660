<template>
  <div class="c-bitts-banner" :class="props.variant">
    <div class="w-full flex items-center justify-center gap-8">
      <FontAwesomeIcon
        v-if="props.icon"
        :icon="props.icon"
        :style="{ height: '16px', width: '16px' }"
      />
      <p name="message" class="c-bitts-banner__message">
        {{ props.message }}
      </p>
      <BittsButton
        v-if="props.button?.text"
        :text="props.button.text"
        size="x-small"
        variant="outline"
        :left-icon="props.button.icon"
        :type="buttonType"
        class="c-bitts-banner__action-button ml-8"
        data-testid="c-bitts-banner__action-button"
        @click="handleAlertClick"
      />
    </div>
    <BittsButton
      v-if="props.dismissable"
      data-testid="c-bitts-banner__dismiss"
      :class="props.variant"
      class="ml-auto mr-8"
      :center-icon="['fak', 'x']"
      :type="buttonType"
      variant="ghost"
      size="x-small"
      @click="emit('dismissed', props.type)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

import BittsButton from '../BittsButton/BittsButton.vue';

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  icon: {
    type: Array,
    default: null,
  },
  button: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  dismissable: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
  variant: {
    type: String,
    default: 'info',
  },
  alert: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['dismissed', 'clicked']);

const variants = {
  INFO: 'info',
  NEUTRAL: 'neutral',
  DANGER: 'danger',
  WARNING: 'warning',
  PRIMARY: 'primary',
  UPSELL: 'upsell',
};

const buttonType = computed(() => {
  if (
    [
      variants.INFO,
      variants.NEUTRAL,
      variants.DANGER,
      variants.UPSELL,
    ].includes(props.variant)
  )
    return 'white';
  return 'primary';
});

function handleAlertClick() {
  emit('clicked', { type: props.type, alert: props.alert });
}
</script>
<style lang="pcss" scoped>
.c-bitts-banner {
  @apply w-full flex items-center justify-center py-8 h-40;

  &.danger {
    @apply text-white bg-gradient-to-r from-danger-background-medium to-danger-text;
  }

  &.warning {
    @apply text-warning-text-strong;
    background: var(
      --background-warning-gradient,
      linear-gradient(
        90deg,
        theme(colors.warning.background) 0%,
        theme(colors.warning.border) 100%
      )
    );

    .c-bitts-banner__action-button {
      @apply bg-transparent;
    }
  }

  &.upsell {
    @apply text-white;
    background: var(
      --background-warning-gradient,
      linear-gradient(
        90deg,
        theme(colors.upsell.background / 0.9) 0%,
        theme(colors.upsell.text) 100%
      )
    );

    .c-bitts-banner__action-button {
      @apply bg-transparent;
    }
  }

  &.info {
    @apply text-white  bg-gradient-to-r from-info-accent to-info-text;
  }

  &.neutral {
    @apply text-white bg-gradient-to-r from-neutral-400 to-neutral-600;
  }

  &.primary {
    @apply text-white bg-gradient-to-t from-denim-400 to-brand-navy;
  }

  &.admin {
    @apply bg-red-300 text-neutral-text-strong;
  }
}

.c-bitts-banner__message {
  @apply text-base;
}
</style>
